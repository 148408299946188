@use '../abstract'as *;

/*-----------------------------------------------------------------------------------

    Theme Name: Educal – Online Learning and Education HTML5 Template
    Author: Theme Pure
    Support: basictheme@gmail.com
    Description: Educal – Online Learning and Education HTML5 Template
    Version: 1.0

-----------------------------------------------------------------------------------

/************ TABLE OF CONTENTS ***************

    01. Common CSS
    02. Header CSS
    03. Hero CSS
    04. Category CSS
    05. Banner CSS
    06. Course CSS
    07. Events CSS
    08. Price CSS
    09. Cta CSS
    10. Product CSS
    11. Services CSS
    12. About CSS
    13. What CSS
    14. Why CSS
    15. Counter CSS
    16. Testimonial CSS
    17. Blog CSS
    18. Slider CSS
    19. Brand CSS
    20. Teacher CSS
    21. Page Title CSS
    22. Contact CSS
    23. Error CSS
    24. Login CSS
    00. Footer CSS


**********************************************/
/*


*/

/*----------------------------------------*/
/*  01. Theme default CSS
/*----------------------------------------*/


* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	font-family: $hind;
	font-size: 16px;
	font-weight: normal;
	color: $body-text-color;
	line-height: 26px;
}

.container {
	@media #{$xxxl} {
		max-width: 1200px;
	}
}

a {
	text-decoration: none;
}

.w-img {
	& img {
		width: 100%;
	}
}

.m-img {
	& img {
		max-width: 100%;
	}
}


a,
.btn,
button,
span,
p,
i,
input,
select,
textarea,
li,
img,
svg path,
*::after,
*::before,
.transition-3,
h1,
h2,
h3,
h4,
h5,
h6 {
	@include transition(.3s);
}

a:focus,
.button:focus {
	text-decoration: none;
	outline: none;
}

a:focus,
a:hover {
	color: inherit;
	text-decoration: none;
}

a,
button {
	color: inherit;
	outline: none;
	border: none;
}

button:hover {
	cursor: pointer;
}

button:focus {
	outline: 0;
	border: 0
}

.uppercase {
	text-transform: uppercase;
}

.capitalize {
	text-transform: capitalize;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $hind;
	color: $heading-color;
	margin-top: 0px;
	font-weight: 700;
	line-height: 1.2;
	@include transition(.3s);
}

h1 {
	font-size: 40px;
}

h2 {
	font-size: 36px;
}

h3 {
	font-size: 30px;
	font-weight: normal;
}

h4 {
	font-size: 20px;
}

h5 {
	font-size: 16px;
}

h6 {
	font-size: 14px;
}

ul {
	margin: 0px;
	padding: 0px;
}

li {
	list-style: none;
}

p {
	font-family: $hind;
	font-size: 16px;
	font-weight: normal;
	color: $body-text-color;
	margin-bottom: 15px;
	line-height: 26px;
}

*::-moz-selection {
	background: $e-blue;
	color: $white;
	text-shadow: none;
}

::-moz-selection {
	background: $e-blue;
	color: $white;
	text-shadow: none;
}

::selection {
	background: $e-blue;
	color: $white;
	text-shadow: none;
}

/*--
    - Input Placeholder
-----------------------------------------*/
*::-moz-placeholder {
	color: $black;
	font-size: 14px;
	opacity: 1;
}

*::placeholder {
	color: $black;
	font-size: 14px;
	opacity: 1;
}


/*--
    - Common Classes
-----------------------------------------*/
.fix {
	overflow: hidden
}

.clear {
	clear: both;
}

.f-left {
	float: left
}

.f-right {
	float: right
}

.z-index-1 {
	z-index: 1;
}

.overflow-y-visible {
	overflow-x: hidden;
	overflow-y: visible;
}

.p-relative {
	position: relative;
}

.p-absolute {
	position: absolute;
}

/*--
    - Background color
-----------------------------------------*/
.grey-bg {
	background: $grey;
}

.grey-bg-2 {
	background: $grey-2;
}

.grey-bg-5 {
	background: $grey-5;
}

.blue-bg {
	background: $e-blue;
}


.blue-bg-3 {
	background: $e-blue-3;
}

.blue-bg-4 {
	background: $e-blue-4;
}
.header-bg{
	background-color: #4f003b;
}
.pink-bg {
	background: $e-pink-3;
}

.purple-bg {
	background: $e-purple-2;
}

.green-bg {
	background: $e-green-2;
}

.white-bg {
	background: $white;
}

.black-bg {
	background: $black;
}

.footer-bg {
	background: $footer-bg;
}


// .primary-bg {
// 	background: #222;
// }

/*--
    - color
-----------------------------------------*/

.text-white h1,
.text-white h2,
.text-white h3,
.text-white h4,
.text-white h5,
.text-white h6,
.text-white p,
.text-white span,
.text-white li,
.text-white a {
	color: $white !important;
}

.white-color {
	color: $white;
}

.theme-color {
	color: $e-blue !important;
}

.black-color {
	color: $black;
}


// spacing

.pl-245 {
	padding-left: 245px;
}

.pr-245 {
	padding-right: 245px;
}

.mb-22 {
	margin-bottom: 22px;
}

.ml-270 {
	margin-left: 270px;
}

.mb--120 {
	margin-bottom: -120px;
}

.mb--100 {
	margin-bottom: -100px;
}

.mt--210 {
	margin-top: -210px;
}

.mt--80 {
	margin-top: -80px;
}

.mb-130 {
	margin-bottom: -130px;
}

.section-padding {
	padding-left: 50px;
	padding-right: 50px;

	@media #{$xl} {
		padding-left: 30px;
		padding-right: 30px;
	}

	@media #{$xs} {
		padding-left: 0px;
		padding-right: 0px;
	}
}

.Benificer{
	color: #003667;
    font-weight: 700;
    text-transform: capitalize;
}
.Benificer:before {
    color: #d2d701;
    content: "•";
    display: inline-block;
    font-weight: 700;
    margin-left: -1em;
    width: 2em;
}
// theme btn
.g-btn {
	display: inline-block;
	height: 50px;
	line-height: 52px;
	text-align: center;
	padding: 0 50px;
	color: $white;
	background: #af2b31;
	@include border-radius(4px);
	font-weight: 500;
	@include sentence-case();

	&:hover {
		color: $white;
		@include box-shadow(0px 10px 24px 0px #4f003b);
	}
}
.e-btn {
	display: inline-block;
	height: 50px;
	line-height: 52px;
	text-align: center;
	padding: 0 25px;
	color: $white;
	background: $e-blue;
	@include border-radius(4px);
	font-weight: 500;
	@include sentence-case();

	&:hover {
		color: $black;
		background: transparent;
		border: 2px solid $border-4;

	}

	&-2 {
		height: 34px;
		line-height: 36px;
		padding: 0 20px;
		font-size: 14px;
		text-transform: capitalize;
	}

	&-3 {
		padding: 0 28px;
	}

	&-4 {
		height: 46px;
		line-height: 44px;
		border: 2px solid $e-blue;
		background: $e-blue;
		color: $white;
	}

	&-5 {
		padding-left: 22px;
		padding-right: 22px;
	}

	&-6 {
		padding-left: 60px;
		padding-right: 60px;
	}

	&-7 {
		height: 44px;
		line-height: 46px;
	}

	&-border {
		height: 46px;
		line-height: 44px;
		border: 2px solid $border-4;
		color: $black;
		background: transparent;

		&:hover {
			background: $e-blue;
			border-color: $e-blue;
			color: $white;
		}

		&-2 {
			height: 46px;
			line-height: 44px;
			border: 2px solid rgba($color: $white, $alpha: .3);
			background: transparent;
			padding: 0 20px;

			&:hover {
				background: $white;
				border-color: $white;
				color: $black;
			}
		}
	}

	&-white {
		height: 54px;
		line-height: 58px;
		background: $white;
		color: $black;
		padding: 0 45px;

		&:hover {
			color: $black;
		}
	}
}


/* link btn */

.link-btn {
	position: relative;
	font-size: 16px;
	color: $e-text-3;
	font-weight: 500;
	padding-right: 21px;
	display: inline-block;

	& i {
		font-size: 14px;
		position: absolute;
		top: 12px;
		@include transform(translateY(-38%));
		transition: all ease .2s;
		-webkit-transition: all ease .2s;
		-moz-transition: all ease .2s;
		-ms-transition: all ease .2s;
		-o-transition: all ease .2s;

		&:first-child {
			right: 10%;
			visibility: hidden;
			opacity: 0;
		}

		&:last-child {
			right: 0;
		}
	}

	&:hover {
		color: $e-blue;

		& i {
			&:first-child {
				right: 0;
				visibility: visible;
				opacity: 1;
			}

			&:last-child {
				right: -10%;
				visibility: hidden;
				opacity: 0;
			}
		}
	}

	&.link-prev {
		padding-right: 0;
		padding-left: 21px;

		& i {
			&:first-child {
				left: 10%;
				right: auto;
			}

			&:last-child {
				left: 0;
				right: auto;
			}
		}

		&:hover {
			& i {
				&:first-child {
					left: 0%;
					right: auto;
				}

				&:last-child {
					left: -10%;
					right: auto;
				}
			}
		}
	}
}

.link-btn-2 {
	position: relative;
	font-size: 12px;
	font-weight: 500;
	color: $black;
	text-transform: uppercase;
	padding-right: 25px;
	width: 40px;
	height: 40px;
	display: inline-block;
	line-height: 36px;
	@include border-radius(50%);
	border: 2px solid rgba($color: $white, $alpha: .2);
	overflow: hidden;

	& i {
		position: absolute;
		top: 50%;
		@include transform(translateY(-50%));
		transition: all ease .2s;
		-webkit-transition: all ease .2s;
		-moz-transition: all ease .2s;
		-ms-transition: all ease .2s;
		-o-transition: all ease .2s;

		&:first-child {
			left: -100%;
			visibility: hidden;
			opacity: 0;

		}

		&:last-child {
			right: 35%;
		}
	}

	&:hover {
		color: $white;

		& i:first-child {
			left: 35%;
			visibility: visible;
			opacity: 1;
		}

		& i:last-child {
			right: -100%;
			visibility: hidden;
			opacity: 0;
		}
	}
}

.table> :not(:first-child) {
	border-top: 0px solid currentColor;
}

// play btn
.play-btn {
	display: inline-block;
	width: 44px;
	height: 44px;
	text-align: center;
	line-height: 47px;
	@include border-radius(50%);
	background: $white;
	color: $e-blue;
	-webkit-animation: pulse 2s infinite;
	-moz-animation: pulse 2s infinite;
	-o-animation: pulse 2s infinite;
	animation: pulse 2s infinite;
	font-size: 12px;
}

@-webkit-keyframes pulse {
	0% {
		-webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
	}

	70% {
		-webkit-box-shadow: 0 0 0 45px rgba(255, 255, 255, 0);
	}

	100% {
		-webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
	}
}

@keyframes pulse {
	0% {
		-moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
	}

	70% {
		-moz-box-shadow: 0 0 0 45px rgba(255, 255, 255, 0);
		box-shadow: 0 0 0 45px rgba(255, 255, 255, 0);
	}

	100% {
		-moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
	}
}

// section title

.section {
	&__sub-title {
		font-size: 18px;
		font-weight: 500;
		color: $e-blue;
		display: inline-block;
		margin-bottom: 5px;
	}

	&__title {
		font-size: 40px;
		color: $black;
		text-transform: capitalize;
		z-index: 1;
		margin-bottom: 3px;

		@media #{$xs} {
			font-size: 33px;
		}

		& span {
			position: relative;

			& img {
				position: absolute;
				left: -14px;
				bottom: 12px;
				z-index: -1;
				-webkit-animation: section-animation 3s infinite;
				animation: section-animation 3s infinite;
			}

			&.yellow-bg-big {
				& img {
					bottom: 15px;
					width: 120%;
					-webkit-animation: section-animation-2 3s infinite;
					animation: section-animation-2 3s infinite;
				}
			}

			&.yellow-bg-sm {
				& img {
					bottom: 10px;
					width: 120%;
					-webkit-animation: section-animation-2 3s infinite;
					animation: section-animation-2 3s infinite;
				}
			}
		}
	}

	&__title-wrapper {
		position: relative;
		z-index: 1;

		& p {
			font-size: 16px;
			color: $e-text-4;
		}
	}
}

@keyframes section-animation {
	0% {
		width: 0;
	}

	15% {
		width: 100%;
	}

	85% {
		opacity: 1;
	}

	90% {
		width: 100%;
		opacity: 0;
	}

	to {
		width: 0;
		opacity: 0;
	}
}

@keyframes section-animation-2 {
	0% {
		width: 0;
	}

	15% {
		width: 125%;
	}

	85% {
		opacity: 1;
	}

	90% {
		width: 125%;
		opacity: 0;
	}

	to {
		width: 0;
		opacity: 0;
	}
}

/* basic pagination */

.basic-pagination {
	& ul {
		& li {
			display: inline-block;
			margin-right: 20px;

			@media #{$xs} {
				margin-right: 5px;
			}

			&.prev,
			&.next {
				& a {
					width: inherit;
					height: inherit;
					line-height: inherit;
					@include border-radius(0);
					border: none;

					&:hover {
						color: $e-blue;
						background: inherit;
					}
				}
			}

			&.active {
				& a {
					color: $white;
					background: $e-blue;
					border-color: $e-blue;
				}
			}

			& a {
				position: relative;
				overflow: hidden;
				background: transparent;
				display: inline-block;
				width: 40px;
				height: 40px;
				line-height: 38px;
				text-align: center;
				@include border-radius(4px);
				font-weight: 500;
				font-size: 16px;
				border: 2px solid #eef0f6;
				color: $black;

				&:hover {
					background: $e-blue;
					color: $white;
					border-color: $e-blue;
				}
			}
		}
	}
}



// overlay

.body-overlay {
	background-color: rgba($color: #000000, $alpha: .5);
	height: 100%;
	width: 100%;
	position: fixed;
	top: 0;
	z-index: 99;
	left: 0;
	opacity: 0;
	visibility: hidden;
	@include transition(.3s);

	&:hover {
		cursor: pointer;
	}
}

.body-overlay.opened {
	opacity: 1;
	visibility: visible;
}


//spacing
.mt-5 {
	margin-top: 5px !important
}

.mt-10 {
	margin-top: 10px
}

.mt-15 {
	margin-top: 15px
}

.mt-20 {
	margin-top: 20px
}

.mt-25 {
	margin-top: 25px
}

.mt-30 {
	margin-top: 30px
}

.mt-35 {
	margin-top: 35px
}

.mt-40 {
	margin-top: 40px
}

.mt-45 {
	margin-top: 45px
}

.mt-50 {
	margin-top: 50px
}

.mt-55 {
	margin-top: 55px
}

.mt-60 {
	margin-top: 60px
}

.mt-65 {
	margin-top: 65px
}

.mt-70 {
	margin-top: 70px
}

.mt-75 {
	margin-top: 75px
}

.mt-80 {
	margin-top: 80px
}

.mt-85 {
	margin-top: 85px
}

.mt-90 {
	margin-top: 90px
}

.mt-95 {
	margin-top: 95px
}

.mt-100 {
	margin-top: 100px
}

.mt-105 {
	margin-top: 105px
}

.mt-110 {
	margin-top: 110px
}

.mt-115 {
	margin-top: 115px
}

.mt-120 {
	margin-top: 120px
}

.mt-125 {
	margin-top: 125px
}

.mt-130 {
	margin-top: 130px
}

.mt-135 {
	margin-top: 135px
}

.mt-140 {
	margin-top: 140px
}

.mt-145 {
	margin-top: 145px
}

.mt-150 {
	margin-top: 150px
}

.mt-155 {
	margin-top: 155px
}

.mt-160 {
	margin-top: 160px
}

.mt-165 {
	margin-top: 165px
}

.mt-170 {
	margin-top: 170px
}

.mt-175 {
	margin-top: 175px
}

.mt-180 {
	margin-top: 180px
}

.mt-185 {
	margin-top: 185px
}

.mt-190 {
	margin-top: 190px
}

.mt-195 {
	margin-top: 195px
}

.mt-200 {
	margin-top: 200px
}

.mb-5 {
	margin-bottom: 5px !important
}

.mb-10 {
	margin-bottom: 10px
}

.mb-15 {
	margin-bottom: 15px
}

.mb-20 {
	margin-bottom: 20px
}

.mb-25 {
	margin-bottom: 25px
}

.mb-30 {
	margin-bottom: 30px
}

.mb-35 {
	margin-bottom: 35px
}

.mb-40 {
	margin-bottom: 40px
}

.mb-45 {
	margin-bottom: 45px
}

.mb-50 {
	margin-bottom: 50px
}

.mb-55 {
	margin-bottom: 55px
}

.mb-60 {
	margin-bottom: 60px
}

.mb-65 {
	margin-bottom: 65px
}

.mb-70 {
	margin-bottom: 70px
}

.mb-75 {
	margin-bottom: 75px
}

.mb-80 {
	margin-bottom: 80px
}

.mb-85 {
	margin-bottom: 85px
}

.mb-90 {
	margin-bottom: 90px
}

.mb-95 {
	margin-bottom: 95px
}

.mb-100 {
	margin-bottom: 100px
}

.mb-105 {
	margin-bottom: 105px
}

.mb-110 {
	margin-bottom: 110px
}

.mb-115 {
	margin-bottom: 115px
}

.mb-120 {
	margin-bottom: 120px
}

.mb-125 {
	margin-bottom: 125px
}

.mb-130 {
	margin-bottom: 130px
}

.mb-135 {
	margin-bottom: 135px
}

.mb-140 {
	margin-bottom: 140px
}

.mb-145 {
	margin-bottom: 145px
}

.mb-150 {
	margin-bottom: 150px
}

.mb-155 {
	margin-bottom: 155px
}

.mb-160 {
	margin-bottom: 160px
}

.mb-165 {
	margin-bottom: 165px
}

.mb-170 {
	margin-bottom: 170px
}

.mb-175 {
	margin-bottom: 175px
}

.mb-180 {
	margin-bottom: 180px
}

.mb-185 {
	margin-bottom: 185px
}

.mb-190 {
	margin-bottom: 190px
}

.mb-195 {
	margin-bottom: 195px
}

.mb-200 {
	margin-bottom: 200px
}

.ml-5 {
	margin-left: 5px
}

.ml-10 {
	margin-left: 10px
}

.ml-15 {
	margin-left: 15px
}

.ml-20 {
	margin-left: 20px
}

.ml-25 {
	margin-left: 25px
}

.ml-30 {
	margin-left: 30px
}

.ml-35 {
	margin-left: 35px
}

.ml-40 {
	margin-left: 40px
}

.ml-45 {
	margin-left: 45px
}

.ml-50 {
	margin-left: 50px
}

.ml-55 {
	margin-left: 55px
}

.ml-60 {
	margin-left: 60px
}

.ml-65 {
	margin-left: 65px
}

.ml-70 {
	margin-left: 70px
}

.ml-75 {
	margin-left: 75px
}

.ml-80 {
	margin-left: 80px
}

.ml-85 {
	margin-left: 85px
}

.ml-90 {
	margin-left: 90px
}

.ml-95 {
	margin-left: 95px
}

.ml-100 {
	margin-left: 100px
}

.ml-105 {
	margin-left: 105px
}

.ml-110 {
	margin-left: 110px
}

.ml-115 {
	margin-left: 115px
}

.ml-120 {
	margin-left: 120px
}

.ml-125 {
	margin-left: 125px
}

.ml-130 {
	margin-left: 130px
}

.ml-135 {
	margin-left: 135px
}

.ml-140 {
	margin-left: 140px
}

.ml-145 {
	margin-left: 145px
}

.ml-150 {
	margin-left: 150px
}

.ml-155 {
	margin-left: 155px
}

.ml-160 {
	margin-left: 160px
}

.ml-165 {
	margin-left: 165px
}

.ml-170 {
	margin-left: 170px
}

.ml-175 {
	margin-left: 175px
}

.ml-180 {
	margin-left: 180px
}

.ml-185 {
	margin-left: 185px
}

.ml-190 {
	margin-left: 190px
}

.ml-195 {
	margin-left: 195px
}

.ml-200 {
	margin-left: 200px
}

.mr-5 {
	margin-right: 5px
}

.mr-10 {
	margin-right: 10px
}

.mr-15 {
	margin-right: 15px
}

.mr-20 {
	margin-right: 20px
}

.mr-25 {
	margin-right: 25px
}

.mr-30 {
	margin-right: 30px
}

.mr-35 {
	margin-right: 35px
}

.mr-40 {
	margin-right: 40px
}

.mr-45 {
	margin-right: 45px
}

.mr-50 {
	margin-right: 50px
}

.mr-55 {
	margin-right: 55px
}

.mr-60 {
	margin-right: 60px
}

.mr-65 {
	margin-right: 65px
}

.mr-70 {
	margin-right: 70px
}

.mr-75 {
	margin-right: 75px
}

.mr-80 {
	margin-right: 80px
}

.mr-85 {
	margin-right: 85px
}

.mr-90 {
	margin-right: 90px
}

.mr-95 {
	margin-right: 95px
}

.mr-100 {
	margin-right: 100px
}

.mr-105 {
	margin-right: 105px
}

.mr-110 {
	margin-right: 110px
}

.mr-115 {
	margin-right: 115px
}

.mr-120 {
	margin-right: 120px
}

.mr-125 {
	margin-right: 125px
}

.mr-130 {
	margin-right: 130px
}

.mr-135 {
	margin-right: 135px
}

.mr-140 {
	margin-right: 140px
}

.mr-145 {
	margin-right: 145px
}

.mr-150 {
	margin-right: 150px
}

.mr-155 {
	margin-right: 155px
}

.mr-160 {
	margin-right: 160px
}

.mr-165 {
	margin-right: 165px
}

.mr-170 {
	margin-right: 170px
}

.mr-175 {
	margin-right: 175px
}

.mr-180 {
	margin-right: 180px
}

.mr-185 {
	margin-right: 185px
}

.mr-190 {
	margin-right: 190px
}

.mr-195 {
	margin-right: 195px
}

.mr-200 {
	margin-right: 200px
}

.pt-5 {
	padding-top: 5px !important
}

.pt-10 {
	padding-top: 10px
}

.pt-15 {
	padding-top: 15px
}

.pt-20 {
	padding-top: 20px
}

.pt-25 {
	padding-top: 25px
}

.pt-30 {
	padding-top: 30px
}

.pt-35 {
	padding-top: 35px
}

.pt-40 {
	padding-top: 40px
}

.pt-45 {
	padding-top: 45px
}

.pt-50 {
	padding-top: 50px
}

.pt-55 {
	padding-top: 55px
}

.pt-60 {
	padding-top: 60px
}

.pt-65 {
	padding-top: 65px
}

.pt-70 {
	padding-top: 70px
}

.pt-75 {
	padding-top: 75px
}

.pt-80 {
	padding-top: 80px
}

.pt-85 {
	padding-top: 85px
}

.pt-90 {
	padding-top: 90px
}

.pt-95 {
	padding-top: 95px
}

.pt-100 {
	padding-top: 100px
}

.pt-105 {
	padding-top: 105px
}

.pt-110 {
	padding-top: 110px
}

.pt-115 {
	padding-top: 115px
}

.pt-120 {
	padding-top: 120px
}

.pt-125 {
	padding-top: 125px
}

.pt-130 {
	padding-top: 130px
}

.pt-135 {
	padding-top: 135px
}

.pt-140 {
	padding-top: 140px
}

.pt-145 {
	padding-top: 145px
}

.pt-150 {
	padding-top: 150px
}

.pt-155 {
	padding-top: 155px
}

.pt-160 {
	padding-top: 160px
}

.pt-165 {
	padding-top: 165px
}

.pt-170 {
	padding-top: 170px
}

.pt-175 {
	padding-top: 175px
}

.pt-180 {
	padding-top: 180px
}

.pt-185 {
	padding-top: 185px
}

.pt-190 {
	padding-top: 190px
}

.pt-195 {
	padding-top: 195px
}

.pt-200 {
	padding-top: 200px
}

.pb-5 {
	padding-bottom: 5px !important
}

.pb-10 {
	padding-bottom: 10px
}

.pb-15 {
	padding-bottom: 15px
}

.pb-20 {
	padding-bottom: 20px
}

.pb-25 {
	padding-bottom: 25px
}

.pb-30 {
	padding-bottom: 30px
}

.pb-35 {
	padding-bottom: 35px
}

.pb-40 {
	padding-bottom: 40px
}

.pb-45 {
	padding-bottom: 45px
}

.pb-50 {
	padding-bottom: 50px
}

.pb-55 {
	padding-bottom: 55px
}

.pb-60 {
	padding-bottom: 60px
}

.pb-65 {
	padding-bottom: 65px
}

.pb-70 {
	padding-bottom: 70px
}

.pb-75 {
	padding-bottom: 75px
}

.pb-80 {
	padding-bottom: 80px
}

.pb-85 {
	padding-bottom: 85px
}

.pb-90 {
	padding-bottom: 90px
}

.pb-95 {
	padding-bottom: 95px
}

.pb-100 {
	padding-bottom: 100px
}

.pb-105 {
	padding-bottom: 105px
}

.pb-110 {
	padding-bottom: 110px
}

.pb-115 {
	padding-bottom: 115px
}

.pb-120 {
	padding-bottom: 120px
}

.pb-125 {
	padding-bottom: 125px
}

.pb-130 {
	padding-bottom: 130px
}

.pb-135 {
	padding-bottom: 135px
}

.pb-140 {
	padding-bottom: 140px
}

.pb-145 {
	padding-bottom: 145px
}

.pb-150 {
	padding-bottom: 150px
}

.pb-155 {
	padding-bottom: 155px
}

.pb-160 {
	padding-bottom: 160px
}

.pb-165 {
	padding-bottom: 165px
}

.pb-170 {
	padding-bottom: 170px
}

.pb-175 {
	padding-bottom: 175px
}

.pb-180 {
	padding-bottom: 180px
}

.pb-185 {
	padding-bottom: 185px
}

.pb-190 {
	padding-bottom: 190px
}

.pb-195 {
	padding-bottom: 195px
}

.pb-200 {
	padding-bottom: 200px
}

.pl-5 {
	padding-left: 5px
}

.pl-10 {
	padding-left: 10px
}

.pl-15 {
	padding-left: 15px
}

.pl-20 {
	padding-left: 20px
}

.pl-25 {
	padding-left: 25px
}

.pl-30 {
	padding-left: 30px
}

.pl-35 {
	padding-left: 35px
}

.pl-40 {
	padding-left: 40px
}

.pl-45 {
	padding-left: 45px
}

.pl-50 {
	padding-left: 50px
}

.pl-55 {
	padding-left: 55px
}

.pl-60 {
	padding-left: 60px
}

.pl-65 {
	padding-left: 65px
}

.pl-70 {
	padding-left: 70px
}

.pl-75 {
	padding-left: 75px
}

.pl-80 {
	padding-left: 80px
}

.pl-85 {
	padding-left: 85px
}

.pl-90 {
	padding-left: 90px
}

.pl-95 {
	padding-left: 95px
}

.pl-100 {
	padding-left: 100px
}

.pl-105 {
	padding-left: 105px
}

.pl-110 {
	padding-left: 110px
}

.pl-115 {
	padding-left: 115px
}

.pl-120 {
	padding-left: 120px
}

.pl-125 {
	padding-left: 125px
}

.pl-130 {
	padding-left: 130px
}

.pl-135 {
	padding-left: 135px
}

.pl-140 {
	padding-left: 140px
}

.pl-145 {
	padding-left: 145px
}

.pl-150 {
	padding-left: 150px
}

.pl-155 {
	padding-left: 155px
}

.pl-160 {
	padding-left: 160px
}

.pl-165 {
	padding-left: 165px
}

.pl-170 {
	padding-left: 170px
}

.pl-175 {
	padding-left: 175px
}

.pl-180 {
	padding-left: 180px
}

.pl-185 {
	padding-left: 185px
}

.pl-190 {
	padding-left: 190px
}

.pl-195 {
	padding-left: 195px
}

.pl-200 {
	padding-left: 200px
}

.pr-5 {
	padding-right: 5px
}

.pr-10 {
	padding-right: 10px
}

.pr-15 {
	padding-right: 15px
}

.pr-20 {
	padding-right: 20px
}

.pr-25 {
	padding-right: 25px
}

.pr-30 {
	padding-right: 30px
}

.pr-35 {
	padding-right: 35px
}

.pr-40 {
	padding-right: 40px
}

.pr-45 {
	padding-right: 45px
}

.pr-50 {
	padding-right: 50px
}

.pr-55 {
	padding-right: 55px
}

.pr-60 {
	padding-right: 60px
}

.pr-65 {
	padding-right: 65px
}

.pr-70 {
	padding-right: 70px
}

.pr-75 {
	padding-right: 75px
}

.pr-80 {
	padding-right: 80px
}

.pr-85 {
	padding-right: 85px
}

.pr-90 {
	padding-right: 90px
}

.pr-95 {
	padding-right: 95px
}

.pr-100 {
	padding-right: 100px
}

.pr-105 {
	padding-right: 105px
}

.pr-110 {
	padding-right: 110px
}

.pr-115 {
	padding-right: 115px
}

.pr-120 {
	padding-right: 120px
}

.pr-125 {
	padding-right: 125px
}

.pr-130 {
	padding-right: 130px
}

.pr-135 {
	padding-right: 135px
}

.pr-140 {
	padding-right: 140px
}

.pr-145 {
	padding-right: 145px
}

.pr-150 {
	padding-right: 150px
}

.pr-155 {
	padding-right: 155px
}

.pr-160 {
	padding-right: 160px
}

.pr-165 {
	padding-right: 165px
}

.pr-170 {
	padding-right: 170px
}

.pr-175 {
	padding-right: 175px
}

.pr-180 {
	padding-right: 180px
}

.pr-185 {
	padding-right: 185px
}

.pr-190 {
	padding-right: 190px
}

.pr-195 {
	padding-right: 195px
}

.pr-200 {
	padding-right: 200px
}