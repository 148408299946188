@use '../abstract' as *;

/*----------------------------------------*/
/*  00. FOOTER CSS START
/*----------------------------------------*/


.footer{
    &__widget{
        &-title{
            padding-top: 5px;
            font-size: 20px;
            color: $white;
            &-2{
                color: $black;
            }
        }
        &-body{
            & p{
                font-size: 16px;
                line-height: 24px;
                color: $e-text-5;
                margin-bottom: 30px;
            }
            &-2{
                & p{
                    color: $e-text-4;
                }
            }
        }
    }
    &__social{
        & ul{
            & li{
                display: inline-block;
                &:not(:last-child){
                    margin-right: 10px;
                }
                & a{
                    display: inline-block;
                    font-size: 15px;
                    width: 44px;
                    height: 44px;
                    line-height: 46px;
                    text-align: center;
                    
                    @include border-radius(4px);
                    color: $white;

                    &:hover{
                        background: transparent;
                        color: #285da1;
                    }
                    &.in{
                     
                        &:hover{
                            background: transparent;
                            color: #f67443;
                        }
                    }
                    &.linkedin{
                       
                        &:hover{
                            background: transparent;
                            color: #0a66c2;
                        }
                    }
                    &.pin{
                     
                        &:hover{
                            background: transparent;
                            color: #d2173f;
                        }
                    }
                }
            }
        }
    }
    &__link{
        & ul{
            & li{
                &:not(:last-child){
                    margin-bottom: 4px;
                }
                & a{
                    color: $e-text-5;
                    &:hover{
                        color: $white;
                    }
                }
            }
        }
        &-2{
            & ul{
                & li{
                    & a{
                        color: $e-text-4;
                        &:hover{
                            color: $e-blue;
                        }
                    }
                }
            }
        }
    }
    &__subscribe{
        &-input{
            position: relative;
            & input{
                width: 100%;
                height: 60px;
                padding: 0 30px;
                padding-right: 50px;
                @include border-radius(4px);
                border: none;
                outline: none;

                &::placeholder{
                    font-size: 14px;
                    color: $e-text-3;
                }
            }
            & button{
                position: absolute;
                top: 0;
                right:0;
                font-size: 18px;
                color: $white;
                background: $e-blue;
                width: 70px;
                height: 60px;
                padding-right: 25px;
                transition: all ease .2s;
                -webkit-transition: all ease .2s;
                -moz-transition: all ease .2s;
                -ms-transition: all ease .2s;
                -o-transition: all ease .2s;
                overflow: hidden;
                & i{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    @include transform(translate(-50%, -50%));
                    transition: all ease .2s;
                    -webkit-transition: all ease .2s;
                    -moz-transition: all ease .2s;
                    -ms-transition: all ease .2s;
                    -o-transition: all ease .2s;
                    line-height: 0px;
                    &:last-child{
                        left: 0%;
                        visibility: hidden;
                        opacity: 0;
                    }
                }
                &:hover{
                    & i{
                        left: 100%;
                        visibility: hidden;
                        opacity: 0;
                        &:last-child{
                            left: 50%;
                            visibility: visible;
                            opacity: 1;
                        }
                    }
                }
            }
        }
        & p{
            font-size: 14px;
            line-height: 20px;
            color: $e-text-6;
            padding-right: 60px;
        }
        &-2{
            & p{
                color: $e-text-3;
            }
        }
    }
    &__pl-70{
        padding-left: 70px;
        @media #{$lg}{
            padding-left: 0;
        }
        @media #{$md}{
            padding-left: 0;
        }
        @media #{$sm}{
            padding-left: 0;
        }
        @media #{$xs}{
            padding-left: 0;
        }
    }
    &__bottom{
        padding: 20px 0;
        border-top: 1px solid $border-5;
        &-2{
            border-color: $border-6;
        }
    }
    &__copyright{
        & p{
            font-size: 14px;
            color: $e-text-6;
            margin-bottom: 0;
            & a{
                color: $white;
            }
        }
        &-2{
            & p{
                color: $e-text-3;
                & a{
                    color: $e-blue;
                }
            }
        }
    }
}